<template>
  <div>
    <!--指标设置-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$fanyi('指标设置')"
        :visible.sync="$parent.indicatorSettingDialogVisible"
        center
        width="674px"
        @open="handleDialogOpen"
    >
      <div ref="dialogContent" class="setGoodsIntendedForSaleEditContainer">
        <div class="templateBtnList">
          <div class="btn" @click="getPalletTplList(1)">
            <div>{{ $fanyi('使用推荐模板') }}</div>
            <div class="btnTips">？</div>
          </div>
          <div class="btn" @click="getPalletTplList(0)">
            <div>{{ $fanyi('使用自定义模板') }}</div>
            <div class="btnTips">？</div>
          </div>
        </div>
        <div class="indicatorListContainer scrollContainer">
          <div v-for="(item,index) in $parent.$parent.editFormOption" v-if="item.show==1" :key="index"
               class="indicatorContainer">
            <div class="indicatorName">
              <div>{{ item.name_trans }}</div>
              <el-popover v-if="item.desc!=undefined&&item.desc!=''" placement="bottom" trigger="hover" width="358">
                <div v-html="item.desc"></div>
                <img slot="reference" class="cursorPointer" src="../../../assets/tipsIcon.png" style="margin-top: 4px">
              </el-popover>
            </div>
            <div v-if="item.element_type == 'SINGLE'"><!--单选-->
              <el-radio-group v-model="item.checkedValue">
                <el-radio v-for="(optionItem,optionIndex) in item.option" v-if="optionItem.show==1" :key="optionIndex"
                          :label="optionIndex+1"
                          @click.native.prevent="optionCustomRadioClickHandler(item,optionIndex+1)">
                  <span v-if="optionItem.name!='自定义价格区间'">{{ optionItem.name_trans }}</span>
                  <div v-else @click.stop="removeEventBubbling">
                    <el-input v-model="$parent.$parent.minPrice" :placeholder="$fanyi('最低价')" size="mini"
                              style="width: 80px;margin-right: 10px"
                              type="number"></el-input>
                    <el-input v-model="$parent.$parent.maxPrice" :placeholder="$fanyi('最高价')" size="mini"
                              style="width: 80px"
                              type="number"></el-input>
                  </div>
                </el-radio>
              </el-radio-group>
              <div v-if="item.name=='发货地区'" class="shipmentsTipsText">
                <div>*{{ $fanyi("山东，江苏，安徽，浙江，福建，上海，湖北，湖南，河南，江西") }}</div>
                <div>{{ $fanyi("以上地区发货预计3天到达国内仓库") }}</div>
              </div>
            </div>
            <div v-else>
              <el-checkbox-group v-model="item.checkedValue">
                <el-checkbox v-for="(optionItem,optionIndex) in item.option" :key="optionIndex"
                             :label="optionIndex+1">{{ optionItem.name_trans }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="customPromptText" @click="customizedIndicatorDialogVisible=true">
          <div>{{ $fanyi("没有您想要筛选的指标") }}？</div>
          <el-popover placement="bottom" trigger="hover" width="358">
            <div>
              {{
                $fanyi('当列表没有您想要的指标进行设置时，您可以添加自定义指标，指标提交后，RAKUMART担当会与您联系进行确认，指标后台审核通过后，可在页面进行选择')
              }}
            </div>
            <img slot="reference" class="tipsIcon cursorPointer" src="../../../assets/tipsIcon.png">
          </el-popover>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
       <el-button @click="$parent.customizedIndicatorDialogVisible=false">{{ $fanyi("取消") }}</el-button>
       <el-button type="primary" @click="indicatorSettingSave">{{ $fanyi("保存") }}</el-button>
    </span>
    </el-dialog>
    <!--自定义指标-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="customizedIndicatorDialogVisible"
        center
        width="860px"
        @close="$parent.indicatorValue='';$parent.remark=''">
      <div class="setGoodsIntendedForSaleEditContainer">
        <div class="header">
          <div class="fromItemContainer">
            <div class="label">
              <span>*</span>
              <div> {{ $fanyi('自定义指标') }}:</div>
            </div>
            <div class="flexAndCenter">
              <el-input v-model="$parent.indicatorValue" :placeholder="$fanyi('请输入名称')" size="mini"
                        style="width: 500px;"/>
              <el-popover placement="bottom" trigger="hover" width="358">
                <div>
                  {{
                    $fanyi('当列表没有您想要的指标进行设置时，您可以添加自定义指标，指标提交后，RAKUMART担当会与您联系进行确认，指标后台审核通过后，可在页面进行选择')
                  }}
                </div>
                <img slot="reference" class="cursorPointer" src="../../../assets/tipsIcon.png"
                     style="margin:2px 0 0 6px">
              </el-popover>
            </div>
          </div>
          <div class="fromItemContainer" style="align-items: normal">
            <div class="label">
              <div> {{ $fanyi('留言') }}:</div>
            </div>
            <el-input v-model="$parent.remark" :rows="5" resize="none" type="textarea"/>
          </div>
        </div>
        <div class="tableContainer">
          <div class="tableLabel">{{ $fanyi('自定义指标申请记录') }}</div>
          <div class="table">
            <ul class="tableHeader">
              <li>{{ $fanyi('添加时间') }}</li>
              <li>{{ $fanyi('自定义指标') }}</li>
              <li>{{ $fanyi('留言') }}</li>
              <li>{{ $fanyi('状态') }}</li>
            </ul>
            <div class="tableFooter">
              <ul v-for="(item,index) in $parent.$parent.palletSuggestList" :key="index">
                <li>{{ item.created_at }}</li>
                <li>
                  <el-popover placement="bottom" trigger="hover" width="358">
                    <div>{{ item.title }}</div>
                    <div slot="reference" class="u-line" style="width: 170px">{{ item.title }}</div>
                  </el-popover>
                </li>
                <li>
                  <el-popover placement="bottom" trigger="hover" width="358">
                    <div>{{ item.remark }}</div>
                    <div slot="reference" class="u-line" style="width: 170px">{{ item.remark }}</div>
                  </el-popover>
                </li>
                <li>{{ item.status_name }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
       <el-button @click="customizedIndicatorDialogVisible=false">{{ $fanyi("取消") }}</el-button>
       <el-button type="primary" @click="customizedIndicatorSave">{{ $fanyi("保存") }}</el-button>
    </span>
    </el-dialog>
    <!--指标模板列表-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$parent.palletTplListDialogTitle"
        :visible.sync="$parent.showPalletTplListDialogVisible"
        center
        width="860px"
        @open="handleDialogOpen"
    >
      <div ref="dialogContent" class="setGoodsIntendedForSaleEditContainer" style="padding-bottom: 20px">
        <div class="palletTplListContainer scrollContainer">
          <div v-for="(item,index) in $parent.palletTplList" :key="index" class="palletTplItemContainer">
            <div class="palletTplName">{{ item.name }}</div>
            <div style="height: 290px">
              <div>
                <div v-for="(conditionItem,conditionIndex) in item.checkedList" :key="conditionIndex"
                     class="checkedText">
                  {{ conditionItem }}
                </div>
              </div>
              <div v-if="item.checkedList.length>8" class="checkedText" style="margin-bottom: 0">...</div>
            </div>
            <div class="palletTplOperationBtnList">
              <div v-if="$parent.templateType==0" class="customOperationBtnList">
                <div @click="templateOperation(item,'use')">{{ $fanyi("使用") }}</div>
                <div @click="templateOperation(item,'edit')">{{ $fanyi("编辑") }}</div>
                <div @click="deleteConditionTemplate(item)">{{ $fanyi("删除") }}</div>
              </div>
              <div v-else class="useBtnContainer" @click="templateOperation(item,'use')">{{ $fanyi("使用") }}</div>
            </div>
          </div>
          <div v-if="$parent.templateType==0&&$parent.palletTplList.length<3" class="addTemplateContainer"
               @click="addConditionTemplate">
            <div class="plusIcon"><i class="el-icon-plus"></i></div>
            <div>{{ $fanyi("新增模板") }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--模板编辑-->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="$fanyi('指标设置')"
        :visible.sync="editConditionTemplateDialogVisible"
        center
        width="674px"
        @close="addConditionTemplateStatus=false"
        @open="handleDialogOpen"
    >
      <div ref="dialogContent" class="setGoodsIntendedForSaleEditContainer">
        <div class="conditionTemplateNameContainer">
          <div class="color333">{{ $fanyi('模板名') }}：</div>
          <el-input v-model="conditionTemplateName" size="mini" style="width: 200px;"/>
        </div>
        <div class="indicatorListContainer scrollContainer">
          <div v-for="(item,index) in $parent.$parent.editFormOption" v-if="item.show==1" :key="index"
               class="indicatorContainer">
            <div class="indicatorName">
              <div>{{ item.name_trans }}</div>
              <el-popover v-if="item.desc!=undefined&&item.desc!=''" placement="bottom" trigger="hover" width="358">
                <div v-html="item.desc"></div>
                <img slot="reference" class="cursorPointer" src="../../../assets/tipsIcon.png" style="margin-top: 4px">
              </el-popover>
            </div>
            <div v-if="item.element_type == 'SINGLE'"><!--单选-->
              <el-radio-group v-model="item.checkedValue">
                <el-radio v-for="(optionItem,optionIndex) in item.option" v-if="optionItem.show==1" :key="optionIndex"
                          :label="optionIndex+1"
                          @click.native.prevent="optionCustomRadioClickHandler(item,optionIndex+1)">
                  <span v-if="optionItem.name!='自定义价格区间'">{{ optionItem.name_trans }}</span>
                  <div v-else @click.stop="removeEventBubbling">
                    <el-input v-model="minPrice" :placeholder="$fanyi('最低价')" size="mini"
                              style="width: 80px;margin-right: 10px"
                              type="number"></el-input>
                    <el-input v-model="maxPrice" :placeholder="$fanyi('最高价')" size="mini"
                              style="width: 80px"
                              type="number"></el-input>
                  </div>
                </el-radio>
              </el-radio-group>
            </div>
            <div v-else>
              <el-checkbox-group v-model="item.checkedValue" @change="checkboxChange(item)">
                <el-checkbox v-for="(optionItem,optionIndex) in item.option" v-if="optionItem.show==1"
                             :key="optionIndex"
                             :label="optionIndex+1">{{ optionItem.name_trans }}
                </el-checkbox>
              </el-checkbox-group>
              <div v-if="item.name=='发货地区'">
                <div v-for="(daysItem,daysIndex) in daysOfArrivalAtDomesticWarehouse" :key="daysIndex"
                     :style="daysIndex==daysOfArrivalAtDomesticWarehouse.length-1?'':'margin-bottom:10px'"
                     class="shipmentsTipsText">
                  <div>*{{ daysItem.region }}</div>
                  <div>以上地区发货{{ daysItem.name }}国内仓库</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="customPromptText" @click="customizedIndicatorDialogVisible=true">
          <div>{{ $fanyi("没有您想要筛选的指标") }}？</div>
          <el-popover placement="bottom" trigger="hover" width="358">
            <div>
              {{
                $fanyi('当列表没有您想要的指标进行设置时，您可以添加自定义指标，指标提交后，RAKUMART担当会与您联系进行确认，指标后台审核通过后，可在页面进行选择')
              }}
            </div>
            <img slot="reference" class="tipsIcon cursorPointer" src="../../../assets/tipsIcon.png">
          </el-popover>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
       <el-button @click="editConditionTemplateDialogVisible=false">{{ $fanyi("取消") }}</el-button>
       <el-button type="primary" @click="conditionTemplateSave">{{ $fanyi("保存") }}</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
import throttle from "@/utlis/throttle";

export default {
  data() {
    return {
      customizedIndicatorDialogVisible: false,
      showPalletTplListDialogVisible: false,
      editConditionTemplateDialogVisible: false,
      templateType: '',
      palletTplListDialogTitle: '',
      palletTplList: [],
      minPrice: '',
      maxPrice: '',
      conditionTemplateName: '',
      daysOfArrivalAtDomesticWarehouse: [],
      addConditionTemplateStatus: false,
    }
  },
  methods: {
    //指标设置保存
    indicatorSettingSave() {
      let str = '';
      let editFormOption = this.$fun.deepClone(this.$parent.$parent.editFormOption);
      editFormOption.forEach((item) => {
        if (item.element_type == 'SINGLE' && item.checkedValue != '') {
          item.option.forEach((optionItem, optionIndex) => {
            if (optionIndex + 1 == item.checkedValue) {
              str += optionItem.name_trans + ',';
            }
          })
        }
        if (item.element_type != 'SINGLE' && item.checkedValue.length > 0) {
          item.option.forEach((optionItem, optionIndex) => {
            if (item.checkedValue.indexOf(optionIndex + 1) != -1) {
              str += optionItem.name_trans + ',';
            }
          })
        }
      })
      str = str.substr(0, str.length - 1);
      this.$parent.indicatorSettingDialogVisible = false;
      this.$parent.$parent.checkedText = str;
    },
    //自定义指标保存
    customizedIndicatorSave() {
      if (this.$parent.indicatorValue == '') {
        this.$message.error(this.$fanyi('请输入名称'))
        return
      }
      throttle(() => {
        this.$api.palletSuggestStore({
          title: this.$parent.indicatorValue,
          remark: this.$parent.remark
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          this.$parent.indicatorValue = '';
          this.$parent.remark = '';
          this.$parent.$parent.getPalletSuggestList();
        })
      }, 3000)
    },
    //滚动条位置恢复
    handleDialogOpen() {
      this.$nextTick(() => {
        // 在DOM更新后恢复滚动位置
        if (this.$refs.dialogContent) {
          this.$refs.dialogContent.scrollTop = 0;
        }
      });
    },
    //单选框单选反选
    optionCustomRadioClickHandler(item, val) {
      item.checkedValue = item.checkedValue === val ? '' : val
    },
    //自定义选项移除默认事件
    removeEventBubbling() {

    },
    //获取模板列表
    getPalletTplList(val) {
      this.templateType = val;
      this.loading = this.$loading({
        lock: true,
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.palletTplListDialogTitle = val == 1 ? this.$fanyi('推荐模板') : this.$fanyi('自定义模板');
      throttle(() => {
        this.$api.getPalletTplList({
          is_system: val,
          page: 1,
          pageSize: 1000
        }).then((res) => {
          this.loading.close();
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          res.data.data.forEach((item) => {
            item.checkedList = [];
            item.condition.forEach((conditionItem) => {
              conditionItem.option.forEach((optionItem) => {
                if (optionItem.selected == 1 && item.checkedList.length < 8) {
                  item.checkedList.push(optionItem.name_trans)
                }
              })
            })
          })
          this.palletTplList = res.data.data;
          this.showPalletTplListDialogVisible = true;
        })
      }, 3000)
    },
    //新增货盘模板
    addConditionTemplate() {
      if (this.$parent.palletTplList.length < 3) {
        this.$parent.$parent.editFormOption = this.$fun.deepClone(this.$parent.$parent.formOption);
        this.conditionTemplateName = this.$fanyi('意向参考商品') + Number(this.palletTplList.length + 1);
        this.editConditionTemplateDialogVisible = true;
        this.addConditionTemplateStatus = true;
      }
    },
    //使用模板或编辑模板
    templateOperation(item, type) {
      if (type == 'use') {
        this.$parent.showPalletTplListDialogVisible = false;
      } else {
        this.editConditionTemplateDialogVisible = true;
        this.conditionTemplateName = item.name;
        this.$parent.$parent.conditionId = item.id;
      }
      this.$parent.$parent.checkedText = '';
      let arr = this.$fun.deepClone(item.condition)
      arr.forEach((arrItem) => {
        if (arrItem.element_type == 'SINGLE') {   //单选
          arrItem.checkedValue = ''
          for (let i = 0; i < arrItem.option.length; i++) {
            if (arrItem.option[i].selected == 1) {
              this.$parent.$parent.checkedText += arrItem.option[i].name_trans + '；';
              arrItem.checkedValue = i + 1;
              if (arrItem.option[i].name == '自定义价格区间') {
                this.minPrice = arrItem.option[i].v[0];
                this.maxPrice = arrItem.option[i].v[1];
              }
              break;
            }
          }
        } else {
          arrItem.checkedValue = []
          arrItem.option.forEach((optionItem, optionIndex) => {
            if (optionItem.selected == 1) {
              this.$parent.$parent.checkedText += arrItem.option[optionIndex].name_trans + '；';
              arrItem.checkedValue.push(optionIndex + 1)
            }
          })
        }
      })
      this.$parent.$parent.checkedText = this.$parent.$parent.checkedText.substr(0, this.$parent.$parent.checkedText.length - 1);
      this.$parent.$parent.editFormOption = arr;
    },
    //指标模板保存
    conditionTemplateSave() {
      this.$confirm(
          this.$fanyi('确认保存该指标模板吗？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        let editFormOption = this.$fun.deepClone(this.$parent.$parent.editFormOption);
        editFormOption.forEach((item) => {
          if (item.element_type == 'SINGLE') {
            item.option.forEach((optionItem, optionIndex) => {
              if (optionIndex + 1 == item.checkedValue) {
                optionItem.selected = 1
                if (optionItem.name == '自定义价格区间') {
                  optionItem.v = [this.minPrice, this.maxPrice]
                }
              } else {
                optionItem.selected = 0
              }
            })
          }
          if (item.element_type != 'SINGLE') {
            item.option.forEach((optionItem, optionIndex) => {
              if (item.checkedValue.indexOf(optionIndex + 1) != -1) {
                optionItem.selected = 1
              } else {
                optionItem.selected = 0
              }
            })
          }
          item.checkedValue = undefined
        })
        let datas = {
          id: this.addConditionTemplateStatus == true ? undefined : this.$parent.$parent.conditionId,
          name: this.conditionTemplateName,
          condition: JSON.stringify(editFormOption),
        }
        this.loading = this.$loading({
          lock: true,
          spinner: "el-icon-loading fontSize50",
          background: "rgba(255, 255, 255, 0.7)",
        });
        if (this.addConditionTemplateStatus == true) {
          this.$api.storeTpl(datas).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.loading.close();
            this.$message.success(this.$fanyi(res.msg));
            this.editConditionTemplateDialogVisible = false;
            this.$parent.getPalletTplList(this.$parent.templateType);
          })
        } else {
          this.$api.updateTpl(datas).then((res) => {
            if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
            this.loading.close();
            this.$message.success(this.$fanyi(res.msg));
            this.editConditionTemplateDialogVisible = false;
            this.addConditionTemplateStatus = false;
            this.$parent.getPalletTplList(this.$parent.templateType);
          })
        }
      }).catch(() => {

      });
    },
    //指标模板删除
    deleteConditionTemplate(item) {
      this.$confirm(
          this.$fanyi('确认删除该指标模板吗？'),
          '注意',
          {
            confirmButtonText: this.$fanyi('确定'), //确定
            cancelButtonText: this.$fanyi('取消'), //取消
            type: "warning",
            center: true,
            dangerouslyUseHTMLString: true,
          }
      ).then(() => {
        this.$api.deleteTpl({
          id: item.id
        }).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg));
          this.$parent.getPalletTplList(this.$parent.templateType);
        })
      }).catch(() => {

      });
    },
    //多选框数量变更
    checkboxChange(item) {
      this.daysOfArrivalAtDomesticWarehouse = [];
      if (item.name == '发货地区') {
        if (item.checkedValue.length > 0) {
          item.option.forEach((optionItem, optionIndex) => {
            if (item.checkedValue.indexOf(optionIndex + 1) != -1) {
              this.daysOfArrivalAtDomesticWarehouse.push({
                region: optionItem.v.join(','),
                name: optionItem.name
              })
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollContainer {
  height: 400px;
  overflow-y: scroll;
}

.setGoodsIntendedForSaleEditContainer {
  padding: 40px 30px 0;

  .conditionTemplateNameContainer {
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 18px;
    display: flex;
    align-items: center;
  }

  .palletTplListContainer {
    display: flex;
    flex-wrap: wrap;

    .palletTplItemContainer {
      background: #F6F6F6;
      border-radius: 6px;
      width: 258px;
      height: 396px;
      margin: 0 12px 12px 0;
      padding: 16px 18px 18px 18px;

      .palletTplName {
        font-weight: bold;
        color: #000000;
        font-size: 16px;
        margin-bottom: 8px;
      }

      .checkedText {
        font-size: 14px;
        color: #666666;
        margin-bottom: 10px;
      }

      .palletTplOperationBtnList {
        margin-top: 20px;

        .customOperationBtnList {
          display: flex;
          align-items: center;

          div {
            width: 70px;
            height: 32px;
            background: #B4272B;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
            line-height: 32px;
            text-align: center;
            font-size: 14px;
          }

          div:nth-child(2) {
            background: #50C136;
            margin: 0 7px;
          }

          div:last-child {
            background: #2C2D43;
          }
        }

        .useBtnContainer {
          width: 100%;
          height: 32px;
          background: #B4272B;
          border-radius: 4px;
          cursor: pointer;
          color: #fff;
          line-height: 32px;
          text-align: center;
          font-size: 14px;
        }
      }
    }

    .palletTplItemContainer:nth-child(3n), .addTemplateContainer {
      margin-right: 0;
    }

    .addTemplateContainer {
      border-radius: 6px;
      width: 258px;
      height: 396px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 1px solid #DFDFDF;
      cursor: pointer;

      .plusIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 1px solid #DFDFDF;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #DFDFDF;
        font-size: 40px;
        margin-bottom: 23px;
      }

      div:last-child {
        font-size: 16px;
        color: #B4272B;
      }
    }
  }

  .templateBtnList {
    display: flex;
    align-items: center;
    margin-bottom: 23px;

    .btn {
      width: 112px;
      height: 32px;
      background: #B4272B;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #FFFFFF;
      cursor: pointer;

      .btnTips {
        width: 16px;
        height: 16px;
        color: #FFFFFF;
        margin-left: 3px;
        border-radius: 50%;
        border: 1px solid #FFFFFF;
        font-size: 12px;
        line-height: 13px;
        padding-left: 4.1px;
      }
    }

    .btn:last-child {
      width: 124px;
      margin-left: 12px;
    }
  }

  .shipmentsTipsText {
    margin: 10px 0 10px;

    div {
      font-size: 12px;
      color: #B4272B;
    }
  }

  .customPromptText {
    display: flex;
    align-items: center;

    div {
      font-size: 14px;
      color: #B4272B;
      text-decoration-line: underline;
      margin-right: 5px;
    }

    .tipsIcon {
      margin-top: 4px;
    }
  }

  .header {
    .goodHistoryInfoContainer {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #DFDFDF;

      .goodHistoryInfoRightContainer {
        margin-left: 10px;

        .goodsTitle {
          font-size: 14px;
          color: #333333;
          height: 15px;
          margin-bottom: 34px;
        }

        .goodHistoryInfoRightFooterContainer {

          .flexAndCenter:first-child {
            margin-bottom: 14px;
          }

          .flexAndCenter {
            div:first-child {
              font-size: 14px;
              color: #999999;
              margin-right: 24px;
            }
          }
        }
      }
    }

    .fromItemContainer {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .label {
        width: 212px;
        min-width: 212px;
        font-size: 14px;
        color: #333333;
        text-align: right;
        margin-right: 24px;
        display: flex;
        justify-content: flex-end;

        span {
          color: #B4272B;
          font-size: 14px;
          margin-right: 6px;
        }
      }

      .palletCheckedContainer {
        div {
          height: 15px;
          font-size: 14px;
          color: #333333;
          width: 401px;
          margin-bottom: 10px;
        }
      }

      /deep/ .el-radio-group {
        margin-top: 3px;
      }

      ///deep/ .el-radio-group, /deep/ .el-radio-group .el-radio {
      //  display: flex;
      //}
      /deep/ .el-checkbox__original {
        display: none;
      }

      .avatar-uploader {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
      }

      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 117px;
        height: 117px;
        line-height: 117px;
        text-align: center;
      }

      .avatar {
        width: 117px;
        height: 117px;
        display: block;
      }
    }
  }

  .indicatorListContainer {

    .indicatorContainer {
      padding-top: 18px;
      border-bottom: 1px solid #DFDFDF;

      .indicatorName {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        div {
          margin-right: 6px;
          font-size: 16px;
          color: #333333;
        }
      }

      .el-radio, .el-checkbox {
        margin-bottom: 12px;
      }

      /deep/ .el-radio-group {
        display: flex;
        flex-wrap: wrap;
      }

      .el-radio {
        display: flex;
        align-items: center;
      }

      /deep/ .el-checkbox__input {
        input[type="checkbox"] {
          width: 0;
          height: 0;
        }

        line-height: 0.1px;
      }
    }

    .indicatorContainer:last-child {
      border-bottom: none;
    }
  }

  .newTipsContainer {
    color: #B4272B;
    font-size: 14px;
    margin: 30px 0 28px;
    text-align: center;
  }

  .footer {
    padding-top: 20px;

    .title {
      font-size: 18px;
      color: #333333;
      margin-bottom: 10px;
    }

    .flex {
      margin-top: 17px;
      padding-bottom: 10px;

      div {
        font-size: 14px;
        color: #B4272B;
        text-decoration-line: underline;
        margin-right: 5px;
      }

      img {
        width: 14px;
        height: 14px;
        margin-top: 4px;
      }
    }
  }

  .tableContainer {
    .tableLabel {
      font-size: 18px;
      color: #333333;
      margin: 20px 0 14px 0;
    }

    .table {
      .tableHeader {
        display: flex;
        align-items: center;
        height: 46px;
        border-radius: 10px 10px 0 0;
        background: #F0F0F0;

        li {
          line-height: 46px;
          flex: 1;
          padding-left: 30px;
        }
      }

      .tableFooter {
        overflow-y: scroll;
        max-height: 150px;
        width: 100%;

        ul {
          height: 50px;
          border-bottom: 1px solid #DFDFDF;
          display: flex;
          align-items: center;
          width: 100%;

          li {
            line-height: 50px;
            flex: 1;
            padding-left: 30px;
          }
        }
      }

      .tableFooter::-webkit-scrollbar {
        width: 0;
      }
    }
  }
}
</style>