<template>
  <div class="historicalPurchaseDataReferencePage">
    <div v-show="$parent.showStatus==false&&$parent.goodsIntendedForSaleList.length>0">
      <div class="historicalPurchaseDataReferenceHeader">
        <div class="left">
          <div>{{ $fanyi('历史购买数据参考') }}</div>
          <el-popover placement="top" trigger="hover" width="358">
            <div>{{ $fanyi('为你统计历史售卖过的商品进行参考') }}</div>
            <img slot="reference" alt="" class="cursorPointer" src="../../../assets/tipsIcon.png"
                 style="margin-top: 5px">
          </el-popover>
        </div>
        <div class="right" @click="$parent.switchoverShowStatus">
          {{ $parent.showStatus == true ? $fanyi("隐藏") : $fanyi("展开") }}
        </div>
      </div>
      <div class="palletListContainer">
        <div v-for="(item,index) in $parent.goodsIntendedForSaleList"
             :key="index"
             :style="item.checked==true?'border: 1px solid #B4272B;':''" class="palletItemContainer"
             @click="item.checked=!item.checked">
          <div class="checkedContainer">
            <input v-model="item.checked" style="cursor: pointer" type="checkbox" @change.stop="$parent.goodsChange"/>
          </div>
          <div class="goodsInfoContainer">
            <div class="goodsInfoHeader">
              <img :src="item.history.pic" alt="">
              <div>
                <div class="u-line-2" style="min-height: 42px">{{ item.history.goods_title }}</div>
                <div class="flexAndCenter">
                  <div class="btn" @click.stop="$parent.setGoodsIntendedForSale(item)">{{ $fanyi('设置') }}</div>
                  <div class="btn" style="margin-left: 8px;background:#2C2D43;"
                       @click.stop="$parent.deletePallet(item)">{{ $fanyi('删除') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flexAndCenter" style="margin-top: 20px">
              <div class="label">{{ $fanyi('历史下单数量') }}</div>
              <div class="fontSize14 color333">{{ item.history.confirm_num_total }} 点</div>
            </div>
            <div class="flexAndCenter" style="margin-top: 10px">
              <div class="label">{{ $fanyi('历史购买价格') }}</div>
              <div class="flexAndCenter fontSize14 color333">
                最低（<span class="colorB4272B">{{ item.history.min_confirm_price }}</span>元）
                <div style="margin-right: 10px">-</div>
                最高（<span class="colorB4272B">{{ item.history.max_confirm_price }}</span>元）
              </div>
            </div>
            <div class="flexAndCenter" style="margin: 10px 0 12px">
              <div class="label">{{ $fanyi('商品原链接') }}</div>
              <div class="flexAndCenter">
                <div class="linkOperationContainer" @click.stop="pageJump(item.history.goods_url)">
                  <img alt="" src="../../../assets/selfOrganizingSourceGoods/linkIcon.png">
                  <div>{{ $fanyi('原url确认') }}</div>
                </div>
                <div class="linkOperationContainer" @click.stop="copy(item.history.goods_url)">
                  <img alt="" src="../../../assets/selfOrganizingSourceGoods/copyIcon.png">
                  <div>{{ $fanyi('复制') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="palletDetailContainer">
            <div class="flexAndCenter">
              <div class="label">{{ $fanyi('商品更新时间') }}</div>
              <div class="fontSize14 color333">{{ item.updated_at }}</div>
            </div>
            <div class="flexAndCenter" style="margin: 10px 0">
              <div class="label">{{ $fanyi('货盘内商品') }}</div>
              <div class="fontSize14 color333">{{ item.user_pallet_product_count }}</div>
            </div>
            <div class="flexAndCenter" style="margin-bottom: 10px">
              <div class="label">{{ $fanyi('商品每日自动补充') }}</div>
              <div class="fontSize14 color333">{{ item.is_auto_add == 1 ? $fanyi('开启') : $fanyi('关闭') }}</div>
            </div>
            <div class="flexAndCenter" style="margin-bottom: 10px">
              <div class="label">{{ $fanyi('通知') }}</div>
              <div class="fontSize14 color333">{{
                  item.notice_email == 0 ? '' : item.notice_system == 1 ? '①邮件；②站内信' : '①邮件'
                }}{{ item.notice_system == 1 && item.notice_email == 0 ? '②站内信' : '' }}
              </div>
            </div>
            <div :style="item.checkedText!=''?'margin-bottom: 14px':'margin-bottom: 113px'" class="flexAndCenter">
              <div class="label">{{ $fanyi('通知时间') }}</div>
              <div class="fontSize14 color333">{{ item.notice_at != null ? item.notice_at : $fanyi('实时通知') }}</div>
            </div>
            <div v-if="item.checkedText!=''" class="palletCheckedTextContainer">
              <el-popover trigger="hover" width="300">
                <div>{{ item.checkedText }}</div>
                <div slot="reference" class="fontSize14 color333 cursorPointer u-line2" style="line-height: 20px">
                  {{ item.checkedText }}
                </div>
              </el-popover>
            </div>
          </div>
          <div class="palletFooterContainer">
            <div class="palletFooterLeftContainer">
              <div v-if="item.run_start_at==null&&item.user_pallet_product_wait_count==0" class="leftTextContainer">
                {{ $fanyi('目前没有商品查找任务，可以点击“立即查找”为您补充商品') }}
              </div>
              <div v-else class="leftTextContainer">
                {{ item.run_from_count }}個の商品から{{ item.run_complete_count }}個の商品がヒットしました
              </div>
              <div class="btnList">
                <div v-if="item.loadingStatus==false" class="btn" @click.stop="$parent.immediatelySelectGoods(item)">
                  {{ $fanyi('立即查找') }}
                </div>
                <div v-if="item.user_pallet_product_wait_count>0" class="btn" style="margin: 0 8px 0 12px;"
                     @click.stop="$parent.pageJump(item)">
                  {{ $fanyi('商品确认') }}
                </div>
                <el-popover v-show="item.user_pallet_product_wait_count>0" trigger="hover" width="300">
                  <div>{{ $fanyi('商品查找完成后，会放入待确认列表，需要人工确认后，加入货盘') }}</div>
                  <div slot="reference" :style="item.user_pallet_product_wait_count>0?'':'margin-left:8px'">？</div>
                </el-popover>
              </div>
            </div>
            <div v-show="item.loadingStatus" class="palletFooterRightContainer">
              <div class="loading-spinner">
                <div>{{ item.loadingProgress }}%</div>
              </div>
              <div class="loadingProgressText">
                {{ item.loadingProgress == 100 ? $fanyi("查找完成") : $fanyi("查找中") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="$parent.showStatus==true" style="background: #fff;margin-bottom: 30px">
      <div class="historicalPurchaseDataReferenceHeader" style="margin-bottom: 0">
        <div class="left">
          <div>{{ $fanyi('历史购买数据参考') }}</div>
          <el-popover placement="top" trigger="hover" width="358">
            <div>{{ $fanyi('为你统计历史售卖过的商品进行参考') }}</div>
            <img slot="reference" alt="" class="cursorPointer" src="../../../assets/tipsIcon.png"
                 style="margin-top: 5px">
          </el-popover>
        </div>
        <div class="right" @click="$parent.switchoverShowStatus">
          {{ $parent.showStatus == true ? $fanyi("隐藏") : $fanyi("展开") }}
        </div>
      </div>
      <div class="clientHistoryTopListContainer">
        <div v-for="(item,index) in $parent.clientHistoryTopList" :key="index" class="clientHistoryTopItemContainer">
          <div v-if="index==0" class="TOP1">TOP{{ index + 1 }}</div>
          <div v-else-if="index==1" class="TOP2">TOP{{ index + 1 }}</div>
          <div v-else-if="index==2" class="TOP3">TOP{{ index + 1 }}</div>
          <div v-else class="TOP4AndTOP5">TOP{{ index + 1 }}</div>
          <div class="goodsInfoContainer">
            <div class="goodsInfoHeader">
              <img :src="item.pic" alt="">
              <div>
                <div class="u-line-2" style="min-height: 42px">{{ item.goods_title }}</div>
                <div class="btn" @click="addPallet(item)">{{ $fanyi('添加到优化列表') }}</div>
              </div>
            </div>
            <div class="flexAndCenter" style="margin-top: 20px">
              <div class="label">{{ $fanyi('历史下单数量') }}</div>
              <div class="fontSize14 color333">{{ item.confirm_num_total }} 点</div>
            </div>
            <div class="flexAndCenter" style="margin-top: 10px">
              <div class="label">{{ $fanyi('历史购买价格') }}</div>
              <div class="flexAndCenter fontSize14 color333">
                最低（<span class="colorB4272B">{{ item.min_confirm_price }}</span>元）
                <div style="margin-right: 10px">-</div>
                最高（<span class="colorB4272B">{{ item.max_confirm_price }}</span>元）
              </div>
            </div>
            <div class="flexAndCenter" style="margin: 10px 0 12px">
              <div class="label">{{ $fanyi('商品原链接') }}</div>
              <div class="flexAndCenter">
                <div class="linkOperationContainer" @click.stop="pageJump(item.goods_url)">
                  <img alt="" src="../../../assets/selfOrganizingSourceGoods/linkIcon.png">
                  <div>{{ $fanyi('原url确认') }}</div>
                </div>
                <div class="linkOperationContainer" @click.stop="copy(item.goods_url)">
                  <img alt="" src="../../../assets/selfOrganizingSourceGoods/copyIcon.png">
                  <div>{{ $fanyi('复制') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flexAndCenter pagination" style="padding: 30px 0;justify-content: center">
        <el-pagination
            :current-page="$parent.clientHistoryTopFrom.page"
            :page-size="$parent.clientHistoryTopFrom.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :total="$parent.clientHistoryTopTotal"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="$parent.clientHistoryTopHandleSizeChange"
            @current-change="$parent.clientHistoryTopHandleCurrentChange">
        </el-pagination>
      </div>
      <div class="recentlyPurchasedDataReference">
        <div class="recentlyPurchasedDataReferenceHeader">
          <div>{{ $fanyi('最近购买数据参考') }}</div>
          <el-popover placement="top" trigger="hover" width="358">
            <div>{{ $fanyi('展示最近购买的商品链接') }}</div>
            <img slot="reference" alt="" class="cursorPointer" src="../../../assets/tipsIcon.png"
                 style="margin-top: 5px">
          </el-popover>
        </div>
        <div class="tableContainer">
          <div class="tableHeader">
            <div>NO</div>
            <div class="flex25">{{ $fanyi('商品链接') }}</div>
            <div>{{ $fanyi('商品图片') }}</div>
            <div>{{ $fanyi('下单数量') }}</div>
            <div>{{ $fanyi('下单价格') }}（元）</div>
            <div>操作</div>
          </div>
          <div class="tableContentContainer">
            <div v-for="(item,index) in $parent.clientRecentBuyTopList" :key="index" class="tableItemContainer">
              <div class="flex1">{{ index + 1 }}</div>
              <div class="flex25">
                <el-link :href="item.goods_url" :underline="false" target="_blank" type="primary">{{
                    item.goods_url
                  }}
                </el-link>
              </div>
              <div class="flex1">
                <el-popover placement="top" trigger="hover">
                  <img :src="item.pic" style="width: 300px;height: 300px"/>
                  <span slot="reference">
                    <img :src="item.pic" style="width: 60px;height: 60px;"/>
                  </span>
                </el-popover>
              </div>
              <div class="flex1 fontSize14 color000">{{ item.confirm_num }}</div>
              <div class="flex1 fontSize14 color000">{{ item.confirm_price }}</div>
              <div class="flex1">
                <div class="fontSize14 colorB4272B cursorPointer" style="text-decoration-line: underline;"
                     @click="addPallet(item)">{{
                    $fanyi('添加到优化列表')
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flexAndCenter pagination" style="padding: 30px 0;justify-content: center">
        <el-pagination
            :current-page="$parent.clientRecentBuyTopFrom.page"
            :page-size="$parent.clientRecentBuyTopFrom.pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :total="$parent.clientRecentBuyTopTotal"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="$parent.clientRecentBuyTopHandleSizeChange"
            @current-change="$parent.clientRecentBuyTopHandleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import throttle from '@/utlis/throttle'

export default {
  methods: {
    //跳转链接
    pageJump(url) {
      window.open(url)
    },
    //复制链接
    copy(url) {
      let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value = url; //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message.success(this.$fanyi('操作成功'));
    },
    //添加到优化列表
    addPallet(item) {
      throttle(() => {
        let editFormOption = this.$fun.deepClone(this.$parent.formOption);
        editFormOption.forEach((item) => {
          item.checkedValue = undefined
        })
        let datas = {
          name: item.goods_title != '' ? item.goods_title : item.goods_url,
          condition: JSON.stringify(editFormOption),
          pallet_type: this.$parent.status_name,
          type: 3,
          image_url: '',
          keyword: '',
          link: item.goods_url,
          notice_system: 0,
          notice_email: 0,
          notice_at: '',
          target_count: '100',
          template_id: '',
          is_auto_add: '1',
          is_auto_confirm: '1'
        }
        this.$api.storePalletCondition(datas).then((res) => {
          if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
          this.$message.success(this.$fanyi(res.msg))
          this.$parent.getPalletConditionList();
        })
      }, 3000)
    },
  }
}
</script>

<style lang="scss" scoped>
.historicalPurchaseDataReferencePage {
  width: 1400px;
  margin: -12px auto 0;

  input[type="checkbox"]:checked {
    background: #B4272B;
    border: none;
  }

  .label {
    width: 104px;
    margin-right: 10px;
    font-size: 14px;
    color: #999999;
  }

  .historicalPurchaseDataReferenceHeader {
    height: 70px;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .left {
      display: flex;
      align-items: center;

      div {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin-right: 7px;
      }
    }

    .right {
      width: 80px;
      height: 36px;
      background: #B4272B;
      border-radius: 4px;
      cursor: pointer;
      line-height: 36px;
      text-align: center;
      font-size: 12px;
      color: #FEFEFE;
    }
  }

  .clientHistoryTopListContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;

    .clientHistoryTopItemContainer {
      width: 436px;
      height: 234px;
      background: #F6F6F6;
      border-radius: 4px;
      margin: 0 16px 16px 0;
      position: relative;
      padding: 15px 55px 0 15px;

      .TOP1, .TOP2, .TOP3, .TOP4AndTOP5 {
        width: 50px;
        height: 24px;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
      }

      .TOP1 {
        background: url("../../../assets/selfOrganizingSourceGoods/TOP1.png") no-repeat;
        background-size: 100% 100%;
      }

      .TOP2 {
        background: url("../../../assets/selfOrganizingSourceGoods/TOP2.png") no-repeat;
        background-size: 100% 100%;
      }

      .TOP3 {
        background: url("../../../assets/selfOrganizingSourceGoods/TOP3.png") no-repeat;
        background-size: 100% 100%;
      }

      .TOP4AndTOP5 {
        background: url("../../../assets/selfOrganizingSourceGoods/TOP4AndTOP5.png") no-repeat;
        background-size: 100% 100%;
      }

      .goodsInfoContainer {
        margin-top: 6px;

        .goodsInfoHeader {
          display: flex;

          img {
            width: 100px;
            height: 100px;
            margin-right: 10px;
          }

          .btn {
            width: 110px;
            height: 32px;
            background: #B4272B;
            border-radius: 4px;
            cursor: pointer;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            color: #FEFEFE;
            margin-top: 26px;
          }
        }

        .linkOperationContainer {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }

          div {
            font-size: 14px;
            color: #0113FF;
          }
        }

        .linkOperationContainer:last-child {
          margin-left: 25px;

          div {
            color: #F9910B;
          }
        }
      }
    }

    .clientHistoryTopItemContainer:nth-child(3n) {
      margin-right: 0;
    }
  }

  .palletListContainer {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 18px 30px;
    display: flex;
    flex-wrap: wrap;

    .palletItemContainer {
      width: 436px;
      border-radius: 4px;
      margin: 0 16px 18px 0;
      padding: 8px 8px 15px 15px;
      border: 1px solid #DFDFDF;
      cursor: pointer;

      .checkedContainer {
        display: flex;
        justify-content: flex-end;
      }

      .goodsInfoContainer {
        margin-top: 6px;

        .goodsInfoHeader {
          display: flex;

          img {
            width: 100px;
            height: 100px;
            margin-right: 10px;
          }

          .btn {
            width: 50px;
            height: 32px;
            background: #B4272B;
            border-radius: 4px;
            cursor: pointer;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            color: #FEFEFE;
            margin-top: 26px;
          }
        }

        .linkOperationContainer {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }

          div {
            font-size: 14px;
            color: #0113FF;
          }
        }

        .linkOperationContainer:last-child {
          margin-left: 25px;

          div {
            color: #F9910B;
          }
        }
      }

      .palletDetailContainer {
        border-top: 1px dashed #999999;
        margin-right: 7px;
        padding-top: 10px;

        .palletCheckedTextContainer {
          width: 406px;
          height: 87px;
          background: #FAF2F2;
          border-radius: 4px;
          padding: 10px 10px 12px 12px;
          margin-bottom: 12px;

          .palletCheckedTextTitle {
            font-weight: bold;
            font-size: 14px;
            color: #B4272B;
            margin-bottom: 16px;
          }
        }
      }

      .palletFooterContainer {
        border-top: 1px dashed #DFDFDF;
        padding: 10px 45px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .palletFooterLeftContainer {
          width: 184px;

          .leftTextContainer {
            font-size: 14px;
            color: #333333;
            line-height: 24px;
            margin-bottom: 17px;

            span {
              color: #B4272B;
            }
          }

          .btnList {
            display: flex;
            align-items: center;

            .btn {
              height: 32px;
              background: #B4272B;
              border-radius: 4px;
              text-align: center;
              cursor: pointer;
              line-height: 32px;
              font-size: 12px;
              padding: 0 10px;
              color: #FEFEFE;
            }

            div:last-child {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              border: 1px solid #B4272B;
              color: #B4272B;
              cursor: pointer;
              line-height: 18px;
              padding-left: 5.1px;
              font-size: 14px;
            }
          }
        }

        .palletFooterRightContainer {
          width: 66px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .loading-spinner {
            width: 66px;
            height: 66px;
            border-radius: 50%;
            border: 4px solid #f3f3f3;
            border-top: 4px solid #3498db;
            animation: spin 1s linear infinite;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #333333;

            div {
              animation: spin 1s linear infinite;
              animation-direction: reverse;
            }
          }

          .loadingProgressText {
            font-size: 14px;
            color: #333333;
            margin-top: 10px;
          }

          @keyframes spin {
            0% {
              transform: rotateZ(0deg);
            }
            100% {
              transform: rotateZ(360deg);
            }
          }
        }
      }
    }

    .palletItemContainer:nth-child(3n) {
      margin: 0 0 18px 0;
    }
  }

  .recentlyPurchasedDataReference {
    padding: 0 30px 30px;

    .recentlyPurchasedDataReferenceHeader {
      height: 70px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      margin-top: -18px;

      div {
        font-weight: bold;
        font-size: 20px;
        color: #333333;
        margin-right: 7px;
      }
    }

    .tableHeader {
      height: 56px;
      background: #F0F0F0;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;

      div {
        flex: 1;
        text-align: center;
        font-size: 14px;
        color: #333333;
      }
    }

    .tableContentContainer {
      .tableItemContainer {
        height: 100px;
        border-bottom: 1px solid #DFDFDF;
        display: flex;
        align-items: center;

        .flex1, .flex25 {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .flex25 {
          flex: 2.5 !important;
        }
      }
    }
  }
}

</style>